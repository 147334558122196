import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { usePlansMe, useSubscriptionMe } from '../api';
import { useAccountingClientSubscriptionSelectedPlan } from '../hooks/useAccountingClientSubscriptionSelectedPlan';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

export type UseSubscriptionDataProps = {
  retryOnMount?: boolean;
};

export const useSubscriptionData = ({ retryOnMount }: UseSubscriptionDataProps = {}) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const [, isSubscriptionFeatureFlagLoading] = useFeature(FeatureFlags.PlatformSubscriptions, false);
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();
  const accountingClientSelectedSubscriptionPlan = useAccountingClientSubscriptionSelectedPlan();
  const {
    data: subscription,
    isFetching: isFetchingMySubscription,
    error: subscriptionError,
  } = useSubscriptionMe({
    enabled: isSubscriptionsEnabled,
    retryOnMount,
  });
  const {
    data: plans,
    isFetching: isFetchingPlans,
    error: plansError,
  } = usePlansMe({
    enabled: isSubscriptionsEnabled,
  });

  const isFetching = isFetchingMySubscription || isFetchingPlans || isSubscriptionFeatureFlagLoading;

  const isError =
    !!plansError ||
    !!(subscriptionError && (subscriptionError.code !== '404' || subscriptionsConfig?.expectOrgsToBeSubscribed));

  return { isFetching, isError, subscription, plans, accountingClientSelectedSubscriptionPlan };
};
