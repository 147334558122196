import { Avatar, ExternalLayout, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { CollectW9FileWidget, ExternalLayoutContentWidget, FooterWidget } from '@melio/vex-widgets';
import React from 'react';

import { W9FileFormModel } from '../../../../types';
import { CollectW9FileSuccessScreen } from '../CollectW9FileSuccess';

export type CollectW9FileScreenProps = {
  accountName: string;
  isSaving: boolean;
  onUploadW9File: (data: W9FileFormModel) => void;
  onTaxPayerButtonClicked: () => void;
  showZenworkW9Form: boolean;
  isLoadingZenworkUrl: boolean;
  onValidationError?: (isRequiredError: boolean) => void;
  onReplaceFile?: () => void;
  showSuccessScreen?: boolean;
};

export const CollectW9FileScreen = forwardRef<CollectW9FileScreenProps, 'div'>(
  (
    {
      accountName,
      isSaving,
      onUploadW9File,
      onTaxPayerButtonClicked,
      showZenworkW9Form,
      isLoadingZenworkUrl,
      onValidationError,
      onReplaceFile,
      showSuccessScreen,
      children: _,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    if (showSuccessScreen) {
      return <CollectW9FileSuccessScreen accountName={accountName} />;
    }

    const title = (
      <Group variant="horizontal" alignItems="center">
        <Avatar name={accountName} size="medium" />
        <Text textStyle="body3Semi">
          {formatMessage('vex.widgets.collectW9File.header.description', {
            companyName: accountName,
          })}
        </Text>
      </Group>
    );
    const footer = <FooterWidget />;
    const content = (
      <ExternalLayoutContentWidget
        data-component="CollectW9FileWidget"
        data-testid="add-w9-file-widget"
        header={{
          title,
        }}
        form={{
          title: formatMessage('vex.widgets.collectW9File.title'),
          description: formatMessage('vex.widgets.collectW9File.description'),
          fields: (
            <CollectW9FileWidget
              onSubmit={onUploadW9File}
              onTaxPayerButtonClicked={onTaxPayerButtonClicked}
              isSaving={isSaving}
              showZenworkW9Form={showZenworkW9Form}
              isLoadingZenworkUrl={isLoadingZenworkUrl}
              onValidationError={onValidationError}
              onReplaceFile={onReplaceFile}
            />
          ),
        }}
      />
    );
    return (
      <ExternalLayout
        ref={ref}
        data-testid="collect-w9-file-screen"
        data-component="CollectW9FileActivity.CollectW9FileScreen"
        {...props}
        content={content}
        footer={footer}
      />
    );
  }
);

CollectW9FileScreen.displayName = 'CollectW9FileActivity.CollectW9FileScreen';
