import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FirstFinancialIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="24" rx="8" fill="#00508D" />
    <path
      d="M6 19L8.65487 6H18L17.4513 8.65909H11.0796L10.5487 11.2443H15.7876L15.2743 13.7557H10.0354L8.97345 19H6Z"
      fill="white"
    />
  </Icon>
);
