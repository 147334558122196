import { mergeWith } from 'lodash';
import _compact from 'lodash/compact';
import { PartnerName, RoleUniqueNames } from '@melio/platform-api';

import { CapOneIcons } from '@/cl/icons/capone';
import { CloverIcons } from '@/cl/icons/clover';
import { FiservIcons } from '@/cl/icons/fiserv';
import { MelioIcons } from '@/cl/icons/melio';
import { UsBankIcons } from '@/cl/icons/usbank';
import { capOneTheme, cloverTheme, fiservTheme, melioTheme, usBankTheme } from '@/cl/theme/partners';
import { gustoTheme } from '@/cl/theme/partners/gusto';
import { OptumBankIcons, optumBankTheme } from '@/cl/theme/partners/optum-bank';
import { PayPalIcons, payPalTheme } from '@/cl/theme/partners/paypal';
import { fiservFirstFinancialConfig } from '@/configByPartner/fiservFirstFinancial.config';
import cloverTranslation from '@/translations/partners/clover.locale.json';
import fiservTranslation from '@/translations/partners/fiserv.locale.json';
import melioTranslation from '@/translations/partners/melio.locale.json';
import paypalTranslation from '@/translations/partners/paypal.locale.json';
import { SettingsCardIdEnum } from './cl/components/SettingsCard/SettingsCard.component';
import { sbbConfig } from './configByPartner/sbb.config';
import capOneTranslation from './translations/partners/capOne.locale.json';
import sbbTranslation from './translations/partners/sbb.locale.json';
import {
  generateMelioRedirectPath,
  generatePaymentPagePath,
  getMeliocomOrgUrl,
} from './utils/generateMelioRedirectPath';
import {
  capitalOneConfig,
  cloverConfig,
  fiservConfig,
  fiservUsBankConfig,
  fiservXDDemoConfig,
  melioConfig,
  standardProviderConfig,
} from './configByPartner';
import {
  Features,
  PartnerConfig,
  PartnersConfig,
  PartnerSettingsConfig,
  SettingsGroupEnum,
} from './partnersConfig.types';

const getDefaultSettings = (isArEnabled?: boolean): PartnerSettingsConfig => {
  return _compact([
    {
      type: SettingsGroupEnum.COMPANY,
      items: [
        SettingsCardIdEnum.ProfileSettings,
        SettingsCardIdEnum.CompanySettings,
        SettingsCardIdEnum.CollaboratorsSetting,
        SettingsCardIdEnum.NotificationPreferences,
        SettingsCardIdEnum.WorkflowsSetting,
        SettingsCardIdEnum.AccountingSoftwareSync,
      ],
    },
    {
      type: SettingsGroupEnum.PAYMENTS,
      items: [
        SettingsCardIdEnum.PaymentMethodsSetting,
        SettingsCardIdEnum.SubscriptionPlansSettings,
        SettingsCardIdEnum.BillingSetting,
      ].concat(!isArEnabled ? [SettingsCardIdEnum.ReceivingMethodsSettings] : []),
    },
    isArEnabled
      ? {
          type: SettingsGroupEnum.AR,
          items: [
            SettingsCardIdEnum.ReceivingMethodsSettings,
            SettingsCardIdEnum.InvoiceItems,
            SettingsCardIdEnum.InvoiceSettings,
            SettingsCardIdEnum.InvoiceEmailNotifications,
          ],
        }
      : null,
    {
      type: SettingsGroupEnum.SUPPORT,
      items: [SettingsCardIdEnum.SupportSetting],
    },
  ]);
};

const getMelioSettings = (isArEnabled?: boolean, isTaxAndReportsEnabled?: boolean): PartnerSettingsConfig => {
  return _compact([
    {
      type: SettingsGroupEnum.COMPANY,
      items: [
        SettingsCardIdEnum.ProfileSettings,
        SettingsCardIdEnum.CompanySettings,
        SettingsCardIdEnum.CollaboratorsSetting,
        SettingsCardIdEnum.NotificationPreferences,
        SettingsCardIdEnum.WorkflowsSetting,
        SettingsCardIdEnum.AccountingSoftwareSync,
      ].concat(isTaxAndReportsEnabled ? [SettingsCardIdEnum.TaxAndReportsSettings] : []),
    },
    {
      type: SettingsGroupEnum.PAYMENTS,
      items: [
        SettingsCardIdEnum.PaymentMethodsSetting,
        SettingsCardIdEnum.SubscriptionPlansSettings,
        SettingsCardIdEnum.BillingSetting,
      ].concat(!isArEnabled ? [SettingsCardIdEnum.ReceivingMethodsSettings] : []),
    },
    isArEnabled
      ? {
          type: SettingsGroupEnum.AR,
          items: [
            SettingsCardIdEnum.ReceivingMethodsSettings,
            SettingsCardIdEnum.InvoiceItems,
            SettingsCardIdEnum.InvoiceSettings,
            SettingsCardIdEnum.InvoiceEmailNotifications,
          ],
        }
      : null,
    {
      type: SettingsGroupEnum.SUPPORT,
      items: [SettingsCardIdEnum.SupportSetting],
    },
  ]);
};

const getStandardPartnerSettings = (): PartnerSettingsConfig => [
  {
    type: SettingsGroupEnum.COMPANY,
    items: [
      SettingsCardIdEnum.ProfileSettings,
      SettingsCardIdEnum.CompanySettings,
      SettingsCardIdEnum.CollaboratorsSetting,
      SettingsCardIdEnum.NotificationPreferences,
      SettingsCardIdEnum.AccountingSoftwareSync,
    ],
  },
  {
    type: SettingsGroupEnum.PAYMENTS,
    items: [
      SettingsCardIdEnum.PaymentMethodsSetting,
      SettingsCardIdEnum.SubscriptionPlansSettings,
      SettingsCardIdEnum.BillingSetting,
    ],
  },
  {
    type: SettingsGroupEnum.SUPPORT,
    items: [SettingsCardIdEnum.SupportSetting],
  },
];

const getFiservSettings = (isArEnabled?: boolean): PartnerSettingsConfig => {
  return _compact([
    {
      type: SettingsGroupEnum.COMPANY,
      items: _compact([
        SettingsCardIdEnum.CompanySettings,
        SettingsCardIdEnum.SubscriptionPlansSettings,
        SettingsCardIdEnum.CollaboratorsSetting,
        SettingsCardIdEnum.AccountingSoftwareSync,
        isArEnabled ? null : SettingsCardIdEnum.PaymentMethodsSetting,
      ]),
    },
    isArEnabled
      ? {
          type: SettingsGroupEnum.PAYMENTS,
          items: [SettingsCardIdEnum.PaymentMethodsSetting],
        }
      : null,
    isArEnabled
      ? {
          type: SettingsGroupEnum.AR,
          items: [
            SettingsCardIdEnum.ReceivingMethodsSettings,
            SettingsCardIdEnum.InvoiceItems,
            SettingsCardIdEnum.InvoiceEmailNotifications,
          ],
        }
      : null,
  ]);
};

export const PartnersConfiguration: PartnersConfig = {
  [PartnerName.Melio]: {
    displayName: 'Melio',
    partnerNameAnalyticsEvents: 'melio',
    partnerProductName: 'Melio',
    theme: melioTheme,
    config: melioConfig,
    icons: MelioIcons,
    translations: melioTranslation,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
        orgId: 'orgId',
        userId: 'userId',
        terms: 'terms',
        mock: 'mock',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        redirectUrl: 'redirect_url',
      },
      redirectWhenMissingToken: true,
    },
    rolesMapping: {
      [RoleUniqueNames.MelioOwner]: 1,
      [RoleUniqueNames.MelioAdmin]: 2,
      [RoleUniqueNames.MelioAccountant]: 3,
      [RoleUniqueNames.MelioContributor]: 4,
    },
    supportEmail: 'support@meliopayments.com',
    showOnboardingSwiper: true,
    allowEditCompanyDetails: true,
    allowEditFirstAndLastName: true,
    hideFooter: true,
    skipLogoutSuccessful: true,
    generatePaymentPagePath,
    features: {
      [Features.GetPaid]: {
        redirectUrls: {
          getPaid: (accessToken) => generateMelioRedirectPath(accessToken, 'get-paid'),
          customers: (accessToken) => generateMelioRedirectPath(accessToken, 'contacts/customers'),
        },
      },
      [Features.AccountingFirm]: {
        redirectUrls: {
          clients: (accessToken) => generateMelioRedirectPath(accessToken, 'companies'),
          team: (accessToken) => generateMelioRedirectPath(accessToken, 'team'),
        },
      },
      [Features.Expenses]: {
        redirectUrls: {
          expenses: (accessToken) => generateMelioRedirectPath(accessToken, 'spend-management'),
        },
      },
      [Features.ReceivingMethods]: {
        learnMoreUrl: {
          zendeskLink:
            'https://melio.zendesk.com/hc/en-us/articles/4416227178908-How-can-I-pay-the-credit-card-fee-on-behalf-of-my-customers',
          melioPaymentsLink:
            'https://help.meliopayments.com/en/articles/5187223-how-can-i-pay-the-credit-card-fee-on-behalf-of-my-customer',
        },
      },
    },
    getCurrentOrgUrl: getMeliocomOrgUrl,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: true,
      approvalByOtherActions: true,
    },
    getSettingsConfig: getMelioSettings,
  },
  [PartnerName.CapitalOne]: {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'capital-one',
    partnerProductName: 'Accounts Payable',
    theme: capOneTheme,
    config: capitalOneConfig,
    icons: CapOneIcons,
    translations: capOneTranslation,
    supportEmail: 'capitaloneaccountspayable@melio.com',
    supportQuestionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'id',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        userRole: 'userRole',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.CapitalOnePrimary]: 1,
      [RoleUniqueNames.CapitalOneAm]: 2,
    },
    showOnboardingSwiper: true,
    showOnboardingBackToPartner: true,
    billsInbox: {
      learnMoreLink: 'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/6482836621852',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      billQbSynced: true,
    },
    getSettingsConfig: getDefaultSettings,
  },
  [PartnerName.Clover]: {
    displayName: 'Clover',
    partnerNameAnalyticsEvents: 'clover',
    partnerProductName: 'Pay Bills',
    tabsVariant: 'neutral',
    theme: cloverTheme,
    config: cloverConfig,
    icons: CloverIcons,
    translations: cloverTranslation,
    supportEmail: 'support-billpay@clover.melio.com',
    supportQuestionIds: [1, 2, 4, 5, 6, 3, 7, 8, 9, 10],
    rolesMapping: {
      [RoleUniqueNames.CloverOwner]: 1,
      [RoleUniqueNames.CloverAdmin]: 2,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'code',
        orgId: 'merchant_id',
        userId: 'employee_id',
      },
      redirectWhenMissingToken: true,
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getDefaultSettings,
  },
  [PartnerName.Fiserv]: {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservConfig,
    icons: UsBankIcons,
    translations: fiservTranslation,
    supportEmail: 'us-bank-payments@melio.com',
    rolesMapping: {
      FiservLevel1: 1,
      FiservLevel2: 2,
      FiservLevel3: 3,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getFiservSettings,
  },
  [PartnerName.FiservXdDemo]: {
    displayName: 'XD demo',
    partnerNameAnalyticsEvents: 'xd-demo-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservXDDemoConfig,
    icons: UsBankIcons,
    translations: fiservTranslation,
    supportEmail: 'fiserv-xd-demo@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getFiservSettings,
  },
  [PartnerName.FiservUsBank]: {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: usBankTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    translations: fiservTranslation,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getFiservSettings,
  },
  [PartnerName.FiservFirstFinancial]: {
    displayName: 'First Financial',
    partnerNameAnalyticsEvents: 'first-financial',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservFirstFinancialConfig,
    icons: FiservIcons,
    translations: fiservTranslation,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getFiservSettings,
  },
  [PartnerName.DeploymentsDemo]: {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    translations: fiservTranslation,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    rolesMapping: {},
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getFiservSettings,
  },
  [PartnerName.Shopify]: {
    // shopify is not in use in the platform-app
  } as PartnerConfig,
  [PartnerName.BuiltTechnologies]: {
    // Built is not in use in the platform-app
  } as PartnerConfig,
  // off-the-shelf partners that we'll eventually move to store the config in db
  gusto: {
    displayName: 'Gusto',
    partnerNameAnalyticsEvents: 'gusto',
    partnerProductName: 'Pay bills',
    config: mergeWith({}, standardProviderConfig, {}),
    theme: gustoTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    icons: MelioIcons,
    translations: melioTranslation,
    getSettingsConfig: getStandardPartnerSettings,
  } as PartnerConfig,
  ['optum-bank-demo']: {
    displayName: 'Optum Bank',
    partnerNameAnalyticsEvents: 'optum-bank-demo',
    partnerProductName: 'Pay bills',
    config: mergeWith({}, standardProviderConfig, {
      settings: {
        isEmbeddedExperience: false,
        partnerProductName: 'Pay Bills',
      },
    }),
    theme: optumBankTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    icons: OptumBankIcons,
    translations: melioTranslation,
    getSettingsConfig: getStandardPartnerSettings,
  } as PartnerConfig,
  paypal: {
    displayName: 'PayPal',
    partnerNameAnalyticsEvents: 'paypal',
    partnerProductName: 'PayPal AP',
    config: mergeWith({}, standardProviderConfig, {
      settings: {
        isEmbeddedExperience: false,
      },
      isLineItemsEnabled: true,
    }),
    theme: payPalTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    icons: PayPalIcons,
    translations: paypalTranslation,
    getSettingsConfig: getStandardPartnerSettings,
  } as PartnerConfig,
  [PartnerName.WellsFargo]: {
    // ????
  } as PartnerConfig,
  [PartnerName.Sbb]: {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'sbb',
    partnerProductName: 'Pay Business Bills',
    theme: capOneTheme,
    config: sbbConfig,
    icons: CapOneIcons,
    translations: sbbTranslation,
    supportEmail: 'support@capitalonebusinessbills.melio.com',
    supportQuestionIds: [1, 5, 2, 13, 8, 10, 9, 3, 11, 12],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'bid',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.CapitalOnePrimary]: 1,
      [RoleUniqueNames.CapitalOneAm]: 2,
    },
    showOnboardingSwiper: true,
    billsInbox: {
      learnMoreLink:
        'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702494960668-How-to-automatically-receive-bills-to-your-Bills-inbox',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      paymentByMeScheduled: false,
      paymentByMeUpdated: false,
      billDueReminder: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
    getSettingsConfig: getDefaultSettings,
  },
};
