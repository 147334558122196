import { CompleteRequiredDetailsFormField, MissingField } from './types';

/**
 * Generates the list of fields that the form should display based on the missing fields.
 *
 * This function also takes care of the fields dependencies, for example, if either taxIdType or taxId is missing,
 * the businessType field should be displayed.
 *
 * @param missingFields The list of missing fields.
 * @returns The list of fields that the form should display.
 */
export function generateFieldsList(missingFields: MissingField[]): Set<CompleteRequiredDetailsFormField> {
  const fieldsList = new Set(missingFields.map((field) => field.name));

  if (fieldsList.has('businessType') || fieldsList.has('taxInfoType') || fieldsList.has('taxInfoIdentifier')) {
    fieldsList.add('businessType');
    fieldsList.add('taxInfoType');
    fieldsList.add('taxInfoIdentifier');
  }

  return fieldsList;
}

export function filterByFieldsList<T extends object>(allFields: T, fieldsList: Set<CompleteRequiredDetailsFormField>) {
  return Object.fromEntries(
    Object.entries(allFields).filter(([key]) => fieldsList.has(key as CompleteRequiredDetailsFormField))
  );
}
