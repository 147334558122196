import { ThemeOptions } from '@melio/penny';

import { Bank } from './illustrations/Bank';
import { Spinner } from './loader';
import { FiservLogo, FiservLogoDark } from './logos';

export const fiservTheme: ThemeOptions = {
  loader: Spinner,
  logos: {
    light: FiservLogo,
    dark: FiservLogoDark,
  },
  fonts: {
    primary: 'Roboto,Arial,sans-serif',
  },
  colors: {
    global: {
      brand: {
        1000: '#002038',
        900: '#003055',
        800: '#004071',
        700: '#00508D',
        600: '#4989BB',
        500: '#639AC5',
        400: '#7FB4DD',
        300: '#92C2E8',
        200: '#A4D1F4',
        100: '#D3ECFF',
      },
    },
  },
  icons: {
    bank: Bank,
  },
};
