import { EmptyIcon } from '@/cl/icons/system/empty.icon';
import FirstFinancialFavIcon from '@/images/firstFinancialFavIcon.svg';
import type { PartnerIcons } from '@/partnersConfig.types';
import { Tax1099Icon } from '../default/taxSummary.icon';
import { AddBillIcon } from './addBill.icon';
import { EmptyBillsIcon } from './emptyBills.icon';
import { EmptyVendorsIcon } from './emptyVendors.icon';
import { ErrorIcon } from './error.icon';
import { ExpiredSessionIcon } from './expiredSession.icon';
import { FirstFinancialIcon } from './firstFinancial.icon';
import { MelioIcon } from './melio.icon';
import { NoBillsFoundIcon } from './noBillsFound.icon';
import { NoVendorsFoundIcon } from './noVendorsFound.icon';
import { VerifyEmailIcon } from './verifyEmail.icon';

export const FiservIcons: PartnerIcons = {
  Favicon: FirstFinancialFavIcon,
  MelioIcon: MelioIcon,
  LogoIcon: FirstFinancialIcon,
  ErrorIcon: ErrorIcon,
  EmptyBillsIcon: EmptyBillsIcon,
  EmptyVendorsIcon: EmptyVendorsIcon,
  NoBillsFoundIcon: NoBillsFoundIcon,
  NoVendorsFoundIcon: NoVendorsFoundIcon,
  VerifyEmailIcon: VerifyEmailIcon,
  TaxSummaryIcon: Tax1099Icon,
  ExpiredSessionIcon: ExpiredSessionIcon,
  BackToPartnerIcon: EmptyIcon,
  StartNewExperienceIcon: EmptyIcon,
  TryDifferentEmailIcon: EmptyIcon,
  AddBillIcon: AddBillIcon,
};
