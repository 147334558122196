import { ThemeOptions } from '@melio/penny';

const GustoLogo: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="69" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.27 3.836c-4.263 0-7.732 3.484-7.732 7.767s3.469 7.767 7.732 7.767c4.262 0 7.73-3.484 7.73-7.767s-3.468-7.767-7.73-7.767zm0 11.853c-2.242 0-4.068-1.835-4.068-4.086 0-2.252 1.826-4.086 4.068-4.086 2.24 0 4.067 1.834 4.067 4.086 0 2.252-1.826 4.086-4.068 4.086zM38.502 10.03l-1.352-.678c-.587-.292-.955-.518-1.103-.685a.815.815 0 01-.225-.548c0-.262.118-.483.35-.655.23-.18.557-.262.972-.262.759 0 1.6.458 2.526 1.381l2.282-2.293a6.01 6.01 0 00-2.14-1.81 5.924 5.924 0 00-2.692-.632c-1.393 0-2.543.411-3.439 1.239-.895.828-1.345 1.823-1.345 2.99 0 1.81 1.185 3.318 3.563 4.515l1.245.625c1.073.543 1.607 1.096 1.607 1.668 0 .31-.149.578-.445.816-.296.239-.688.352-1.174.352-.45 0-.966-.155-1.547-.47a5.206 5.206 0 01-1.518-1.228l-2.27 2.484c1.28 1.68 2.987 2.526 5.133 2.526 1.613 0 2.9-.441 3.854-1.317.96-.875 1.44-1.971 1.44-3.282 0-.983-.266-1.846-.794-2.579-.533-.733-1.511-1.447-2.928-2.156zm-22.956 7.648V4.145h-3.67v.906A7.652 7.652 0 007.73 3.836C3.468 3.836 0 7.32 0 11.603s3.468 7.767 7.731 7.767a7.656 7.656 0 004.103-1.185c0 .119-.006.226-.006.298 0 2.251-1.826 4.086-4.067 4.086a4.044 4.044 0 01-2.152-.626l-1.815 3.193a7.656 7.656 0 003.973 1.12c4.263 0 7.779-3.485 7.779-7.768 0-.166.011-.655 0-.81zm-7.815-1.99c-2.24 0-4.067-1.834-4.067-4.085 0-2.252 1.826-4.086 4.067-4.086s4.067 1.834 4.067 4.086c0 2.251-1.826 4.086-4.067 4.086zM30.165 4.14h-3.688v7.384c.006 1.049.006 2.86-.764 3.634-.368.369-.771.703-1.619.703-.854 0-1.257-.334-1.624-.703-.771-.775-.765-2.591-.76-3.634V4.14h-3.687v7.362c-.012 1.518-.03 4.336 1.82 6.206 1.085 1.096 2.378 1.656 4.245 1.656 1.868 0 3.16-.554 4.245-1.656 1.85-1.87 1.832-4.694 1.82-6.206l.012-7.363zm20.899 10.888c-.296.226-1.014.727-1.826.614-.664-.09-1.203-.578-1.286-2.21V7.309h4.334V4.128h-4.334V0h-3.676v.81h-.006v12.14c0 1.93.486 6.414 4.962 6.414 2.265-.035 3.64-1.268 4.216-1.75l.077-.06-2.206-2.728c-.006.005-.16.13-.255.202z"
      fill="#F45D48"
    />
  </svg>
);
// off-the-shelf partners that we'll eventually move to store the config in db
export const gustoTheme: ThemeOptions = {
  logos: {
    dark: GustoLogo,
    light: GustoLogo,
  },
  colors: {
    global: {
      brand: {
        100: '#9DCCCC',
        200: '#6CB3B3',
        300: '#3B9999',
        400: '#3B9999',
        500: '#0A8080',
        600: '#0A8080',
        700: '#0A8080',
        800: '#086666',
        900: '#086666',
        1000: '#064D4D',
      },
    },
  },
};
