import { useMtlMessages, useMtlSchemaValidations } from '@melio/ap-domain';
import { OrganizationBusinessType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { AnySchema, object, SchemaOf } from 'yup';

import { CompleteRequiredDetailsFormField, CompleteRequiredDetailsFormFields } from './types';
import { filterByFieldsList } from './utils';

type DynamicSchemaFields = {
  [K in CompleteRequiredDetailsFormField]: SchemaOf<CompleteRequiredDetailsFormFields[K]>;
};

type Options = {
  isSkippable: boolean;
  taxIdNumberValidate: boolean;
};

/**
 * Returns a validation schema for the complete required details form
 *
 * By default, the schema is generated as a mix of required and not required fields (specifically date of birth).
 * If the form is not dismissible, all fields are marked as required.
 *
 * @param fieldsList
 * @param options
 */
export const useCompleteRequiredDetailsValidationSchema = (
  fieldsList: Set<CompleteRequiredDetailsFormField>,
  options: Options
) => {
  const { formatMessage } = useMelioIntl();
  const {
    labels: { company: companyLabels },
  } = useMtlMessages();
  const { name, companyName, dateOfBirth, address, phoneNumber, industry, businessType, taxIdType, taxId } =
    useMtlSchemaValidations();

  let allFields: Record<string, AnySchema> = {
    firstName: name('firstName'),
    lastName: name('lastName'),
    companyName: companyName(),
    dateOfBirth: dateOfBirth(), // dateOfBirth is always required
    address: address('operating-address'),
    legalCompanyName: companyName(),
    phoneNumber: phoneNumber(),
    legalAddress: address('legal-address'),
    industry: industry(),
    businessType: businessType(),
    taxInfoType: taxIdType().test('ein-only-allowed', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { businessType }: { businessType: string | null } = this.parent;
      if (businessType) {
        if (!value) {
          return this.createError({
            message: formatMessage('app.mtl.validations.taxInfo.type.required'),
          });
        } else if (businessType !== 'sole-proprietorship' && value !== 'EIN') {
          return this.createError({
            message: companyLabels.einOnlyHelperText(businessType as OrganizationBusinessType),
          });
        }
      }
      return true;
    }),
    taxInfoIdentifier: options.taxIdNumberValidate ? taxId('taxInfoType') : taxId('taxInfoType').notRequired(),
    contactFirstName: name('firstName'),
    contactLastName: name('lastName'),
  };

  // If the form is marked isSkippable, we mark all the fields as notRequired
  if (options.isSkippable) {
    allFields = Object.fromEntries(
      Object.entries(allFields).map(([key, value]) => {
        if (key !== 'dateOfBirth' && key !== 'taxInfoIdentifier') {
          return [key, value.notRequired()];
        } else {
          return [key, value];
        }
      })
    );
  }

  return object().shape(filterByFieldsList(allFields, fieldsList) as DynamicSchemaFields);
};
