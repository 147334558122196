import {
  BillsEmailInboxDashboardView,
  PaymentSchedulingPreference,
  VendorDetailsFormType,
} from '@melio/platform-provider';

import { fiservLinks } from '@/links';
import { extendDefaultConfig } from './partnerConfig.utils';

export const fiservXDDemoConfig = extendDefaultConfig({
  default: {
    tabTitle: 'Pay Bills',
    settings: {
      expiredSessionTime: 10,
      fundingSourcePolicy: {
        'bank-account': {
          fundingSourceGroup: 'bank-account',
          read: true,
          write: false,
        },
        credit: {
          fundingSourceGroup: 'credit',
          read: true,
          write: false,
        },
        debit: {
          fundingSourceGroup: 'debit',
          read: false,
          write: false,
        },
      },
      batchPayments: {
        showLateIndicator: false,
      },
      partnerProductName: 'Pay Bills',
      trustedPartners: ['Mastercard', 'Quickbooks', 'Visa'],
      supportEmail: 'support@melio.com',
      eoyCheck: {
        enabled: true,
        promoteFastCheck: true,
        link: '',
      },
      helpCenterUrl: '',
      billsEmailInboxDashboardView: BillsEmailInboxDashboardView.IconAndLink,
      isUnilateralDeliveryMethodSupported: false,
      isEmbeddedExperience: true,
      isPaymentApprovalLimitEnabled: true,
      isDeclinePaymentReasonEnabled: false,
      collectMissingCompanyLegalInfoFields: false,
      collaborator: {
        isAddCollaboratorEnabled: false,
        isChangeCollaboratorPermissionLevelEnabled: true,
        showRoleTransferDisclaimer: true,
      },
      isEntitlementsEnabled: true,
      isLineItemsEnabled: true,
      newBillExperience: {
        isRecurringEnabled: false,
        shouldShowIconInBillsTabCta: true,
        invoiceNumberFormatRegexp: /^[\x20\x2C-\x2E\x30-\x39\x41-\x5A\x61-\x7A']+$/,
        noteToSelfFormatRegexp: /^[\x20\x2C-\x2E\x30-\x39\x41-\x5A\x61-\x7A']+$/,
      },
      payDashboardSearchLabelDisabled: false,
      newPayExperience: {
        isEnabled: true,
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/fiserv/illustrations/first-time-experience.png',
          mobileImageUrl:
            'https://platform-static.meliopayments.com/assets/fiserv/illustrations/first-time-experience.png',
          videoLink: '',
        },
      },
      vendor: {
        collectedDetails: 'extended',
        vendorSelect: {
          createVendor: {
            showVendorDetailsForm: true,
            vendorDetailsFormType: VendorDetailsFormType.Drawer,
          },
        },
        createVendor: {
          shouldSwitchToExtendedFormOnApiErrors: true,
          hasSkipDeliveryMethodButton: false,
          canAddVendorWithTheSameName: true,
          companyNameFormatRegexp: /^[ a-zA-Z0-9!"$%&'()*+,\-'./\\:;=?@]+$/,
          companyNameMaxLength: 32,
          companyNameMinLength: 2,
          shouldShowBankDetailsFields: true,
          shouldShowAddressDetailsBanner: true,
        },
        forms: {
          shouldUseZipCodeMask: true,
          shouldUseSearchVendorSectionLabel: false,
          shouldUseTooltipsForManagedVendorForm: false,
        },
      },
      isMultiColoredAvatarsEnabled: false,
      payment: {
        editing: {
          disableEditDeliveryMethod: true,
        },
        isConfirmationNumberEnabled: true,
        scheduling: {
          scheduleBy: PaymentSchedulingPreference.ByDeliveryDate,
          showEarlierApprovalDate: true,
          maxDeliveryDateInDays: 364,
          showFuturePaymentBanner: true,
          disableEditDeliveryDateForCards: true,
        },
        scheduled: {
          showZeroFees: false,
          showEstimatedDeliveryDateBanner: true,
        },
        allowNotifyingVendor: false,
        memoToVendor: {
          maxLength: 34,
          deliveryMethodBlackList: [
            'bank-account',
            'biller-account',
            'card',
            'domestic-wire-account',
            'virtual-account',
            'international-account',
            'virtual-card',
            'managed-account',
          ],
        },
      },
      syncProgressIndicationEnabled: false,
      showCheckMarkIconForManagedVendor: false,
      singlePaymentStepLayout: {
        backgroundColor: 'white',
      },
      subscriptions: {
        expectOrgsToBeSubscribed: true,
        links: {},
      },
      InvalidVendorBankAccountModal: {
        enabled: true,
        flagName: 'platform-invalid-vendor-bank-account-modal',
      },
      PayDashboardTimelineDisableDates: {
        flagName: 'pay-dashboard-timeline-disable-dates',
        enabled: true,
      },
    },
    services: {
      forethoughtApiKey: '74ba8f52-1c1e-4b60-8b1e-bc74bcdd4a0d',
      zendeskKey: 'f7a6bedc-a1d0-49ef-855c-8297879a42d1',
      zendeskWorkflow: 'U.S. Bank',
    },
    tutorialVideo: '',
    zendesk: {
      headerColor: '#C3C4C2',
      resultListsColor: '#C3C4C2',
      themeColor: '#C3C4C2',
      logo: undefined,
      title: 'Support',
    },
    links: fiservLinks,
  },
  prod: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.tabapay.com/SSOMelioXdDemo.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.tabapay.com/SSOMelioXdDemo.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.tabapay.com/SSOMelioXdDemo.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
  dev: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioXdDemo.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioXdDemo.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioXdDemo.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
});
