/* eslint-disable max-lines */
import { Box } from '@chakra-ui/react';
import { isEinOnlyBusinessType, masks, useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { Form, SectionBanner, SelectOption, Text, useMelioForm } from '@melio/penny';
import { Industry } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';

import { AddressSearchWidget, IndustryTypeSelectWidget } from '../form-controls';
import { CompleteRequiredDetailsFormFields, CompleteRequiredDetailsProps } from './types';
import { useAnalyticsReportFailures } from './useAnalyticsReportFailures';
import { useCompleteRequiredDetailsValidationSchema } from './useCompleteRequiredDetailsValidationSchema';
import { usePopulateDefaultValues } from './usePopulateDefaultValues';
import { generateFieldsList } from './utils';

// Please read this FIRST!
//
// This file is meant to be the centralized form for handling the MTL/KYC requirements. All of them.
// It is tempting to break this apart to multiple files, but I only have one thing to say about this: Don't.
//
// If you read anything in this file that seems a bit "off", please stop and consider your steps. This file
// was created meticulously to handle all the edge cases and requirements that the MTL/KYC process has.

export const CompleteRequiredDetailsForm = ({
  missingFields,
  onSubmit,
  isLoading,
  isSaving,
  onSubmissionStateChange,
  trackClick,
  account,
  ctaLabel,
  isSkippable,
  setIsDismissAllowed,
}: CompleteRequiredDetailsProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    labels: { user: userLabels, company: companyLabels },
    placeholders,
    emptyState,
  } = useMtlMessages();
  const { businessTypeOptionsSelectNew: businessTypeOptions, taxIdTypeOptions } = useMtlFormValues();

  // This is the REAL LIST of fields that the form should display. From this point onwards, you should
  // ignore the missingFields prop and use this list instead.
  const fieldsList = generateFieldsList(missingFields);

  const defaultValues = usePopulateDefaultValues(fieldsList, account);
  const [taxIdNumberValidate, setTaxIdNumberValidate] = useState<boolean>(false);

  // Unfortunately, there's no good way to check if we should allow the user to dismiss the form
  // without checking the fields list. We could have relied on the schema, but it might contain fields that
  // are conditionally required and we don't want to rely on it.
  useEffect(() => {
    if (isSkippable) {
      setIsDismissAllowed(!fieldsList.has('dateOfBirth'));
    }
  }, [fieldsList, setIsDismissAllowed, isSkippable]);

  /**
   * A special submit handler that filters out the fields that were not touched. This is important for
   * the upstream components as it saves them the hassle to figure out what should actually be added
   * to the API call to update the account.
   *
   * @param data The form data as received from useMelioForm
   * @param dirtyFields The dirtyFields state as received from useMelioForm result
   */
  const onSubmitHandler = (
    data: CompleteRequiredDetailsFormFields,
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<CompleteRequiredDetailsFormFields>>>
  ) => {
    const actuallyUpdated = Object.fromEntries(
      Object.entries(data).filter(([key]) => dirtyFields[key as keyof CompleteRequiredDetailsFormFields])
    );

    return onSubmit(actuallyUpdated as CompleteRequiredDetailsFormFields);
  };

  const schema = useCompleteRequiredDetailsValidationSchema(fieldsList, { isSkippable, taxIdNumberValidate });
  const {
    formProps,
    registerField,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { submitCount, errors, dirtyFields },
  } = useMelioForm<CompleteRequiredDetailsFormFields>({
    onSubmit: (data: CompleteRequiredDetailsFormFields) => {
      onSubmitHandler(data, dirtyFields);
    },
    onSubmissionStateChange,
    schema,
    isLoading,
    isSaving,
    defaultValues,
  });

  useAnalyticsReportFailures<CompleteRequiredDetailsFormFields>(
    submitCount,
    errors,
    getValues,
    missingFields,
    ctaLabel
  );

  const handleIndustryCreatable = (option: SelectOption) => {
    const { name, naicsCode } = option.value as unknown as Industry;
    setValue('industry', { name, naicsCode: naicsCode || 999999 });
    void trigger('industry');
  };

  const selectedBusinessType = watch('businessType') || null;
  const selectedTaxIdType = watch('taxInfoType') || null;
  const einOnlyBusinessType = isEinOnlyBusinessType(selectedBusinessType);

  const shouldShowTitles = new Set(missingFields.map(({ entity }) => entity)).size > 1;
  const showBusinessTypeSection =
    fieldsList.has('businessType') || fieldsList.has('taxInfoType') || fieldsList.has('taxInfoIdentifier');
  const taxIdTooltipLabel = formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.existing.tooltip`);

  // This handler is needed because TaxID type is initially hidden and we don't want to apply a mask unless
  // the user starts editing it.
  const [applyMask, setApplyMask] = useState(false);
  const handleTaxIdFocus = () => {
    if (!applyMask) {
      setValue('taxInfoIdentifier', '');
      setApplyMask(true);
      setTaxIdNumberValidate(true);
    }
  };

  return (
    <Form
      data-component="CompleteRequiredDetailsForm"
      data-testid="complete-required-details-form"
      {...formProps}
      columns={2}
    >
      {shouldShowTitles && (
        <Form.ContentBox colSpan={2}>
          <Box marginTop={4}>
            <Text textStyle="body4Semi">
              {formatMessage('widgets.completeRequiredDetailsModal.stage.personal-details.title')}
            </Text>
          </Box>
        </Form.ContentBox>
      )}

      {fieldsList.has('firstName') && (
        <Form.TextField
          {...registerField('firstName')}
          labelProps={{ label: userLabels.firstName }}
          placeholder={placeholders.personalFirstName}
          colSpan={fieldsList.has('lastName') ? 1 : 2}
          isRequired={false}
        />
      )}

      {fieldsList.has('lastName') && (
        <Form.TextField
          {...registerField('lastName')}
          labelProps={{ label: userLabels.lastName }}
          placeholder={placeholders.personalLastName}
          colSpan={fieldsList.has('firstName') ? 1 : 2}
          isRequired={false}
        />
      )}

      {fieldsList.has('dateOfBirth') && (
        <Form.TextField
          {...registerField('dateOfBirth')}
          maskProps={{
            mask: masks.dateOfBirth,
            keepCharPositions: false,
            guide: true,
            placeholderChar: ' ',
          }}
          labelProps={{ label: userLabels.dateOfBirth }}
          placeholder={placeholders.dateOfBirth}
          colSpan={2}
          isRequired
          onClick={() => trackClick('submit-details', 'insert-dob')}
        />
      )}

      {shouldShowTitles && (
        <Form.ContentBox colSpan={2}>
          <Box marginTop={4}>
            <Text textStyle="body4Semi">
              {formatMessage('widgets.completeRequiredDetailsModal.stage.business-details.title')}
            </Text>
          </Box>
        </Form.ContentBox>
      )}

      {fieldsList.has('companyName') && (
        <Form.TextField
          {...registerField('companyName')}
          labelProps={{ label: companyLabels.name }}
          placeholder={placeholders.companyName}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('address') && (
        <AddressSearchWidget
          {...registerField('address')}
          labelProps={{ label: companyLabels.address }}
          placeholder={placeholders.address}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('businessType') && (
        <Form.SelectNew
          {...registerField('businessType')}
          labelProps={{
            label: companyLabels.businessType,
          }}
          options={businessTypeOptions}
          placeholder={placeholders.businessType}
          emptyState={{ label: emptyState.businessType }}
          colSpan={2}
          isRequired={false}
        />
      )}

      {showBusinessTypeSection && einOnlyBusinessType && (
        <Form.ContentBox colSpan={2}>
          <SectionBanner
            description={formatMessage('widgets.completeLegalInfo.taxInfo.identifier.banner.EIN')}
            data-testid="taxInfoBanner"
          />
        </Form.ContentBox>
      )}

      {fieldsList.has('taxInfoType') && (
        <Form.RadioGroup
          {...registerField('taxInfoType')}
          isHidden={!selectedBusinessType}
          labelProps={{
            label: einOnlyBusinessType
              ? formatMessage('widgets.completeLegalInfo.taxInfo.type.label.confirm')
              : formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.label'),
          }}
          options={taxIdTypeOptions(selectedBusinessType)}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('taxInfoIdentifier') && (
        <Form.TextField
          {...registerField('taxInfoIdentifier')}
          data-private
          labelProps={{
            label: formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.unknown.label`),
            tooltipProps: taxIdTooltipLabel
              ? {
                  label: taxIdTooltipLabel,
                }
              : undefined,
          }}
          placeholder={placeholders.taxId(selectedTaxIdType ?? undefined)}
          helperTextProps={{
            label:
              einOnlyBusinessType && selectedBusinessType ? companyLabels.einOnlyHelperText(selectedBusinessType) : '',
          }}
          isHidden={!selectedBusinessType}
          maskProps={{
            mask: masks.taxId[applyMask ? selectedTaxIdType || 'any' : 'any'],
          }}
          onFocus={handleTaxIdFocus}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('legalCompanyName') && (
        <Form.TextField
          {...registerField('legalCompanyName')}
          labelProps={{ label: companyLabels.legalName }}
          placeholder={placeholders.legalCompanyName}
          helperTextProps={{ label: formatMessage('widgets.completeLegalInfo.legalCompanyName.helperText') }}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('legalAddress') && (
        <AddressSearchWidget
          {...registerField('legalAddress')}
          labelProps={{ label: companyLabels.legalAddress }}
          placeholder={placeholders.legalAddress}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('industry') && (
        <IndustryTypeSelectWidget
          {...registerField('industry')}
          onCreateOption={handleIndustryCreatable}
          labelProps={{ label: companyLabels.industry }}
          placeholder={placeholders.industry}
          colSpan={2}
          isRequired={false}
        />
      )}

      {fieldsList.has('contactFirstName') && (
        <Form.TextField
          {...registerField('contactFirstName')}
          labelProps={{ label: companyLabels.contactFirstName }}
          placeholder={placeholders.firstName}
          colSpan={fieldsList.has('contactLastName') ? 1 : 2}
          isRequired={false}
        />
      )}

      {fieldsList.has('contactLastName') && (
        <Form.TextField
          {...registerField('contactLastName')}
          labelProps={{ label: companyLabels.contactLastName }}
          placeholder={placeholders.lastName}
          colSpan={fieldsList.has('contactFirstName') ? 1 : 2}
          isRequired={false}
        />
      )}

      {fieldsList.has('phoneNumber') && (
        <Form.PhoneField
          {...registerField('phoneNumber')}
          labelProps={{ label: companyLabels.phoneNumber }}
          placeholder={placeholders.phoneNumber}
          colSpan={2}
          isRequired={false}
        />
      )}
    </Form>
  );
};
