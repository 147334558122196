import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { useAccountingPlatformEntities } from '@melio/ap-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { useBaseAnalytics } from '@melio/platform-analytics/src/hooks/useAnalytics';
import { useAccount, useAccountsWithLogoUrl, useFeeCatalog, useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';
import { SubscriptionWrapper, useSubscriptionData } from '@melio/subscriptions';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { WithKYCComplianceRouteGuard } from '@/components/risk/WithKYCComplianceRouteGuard';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useFundingSourcesObserver } from '@/hooks/paymentMethods.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useUserActivityInterval, useUserInactivityInterval } from '@/hooks/session.hooks';
import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { useVendorsObserver } from '@/hooks/vendors.hooks';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';

export const WithAnonymousInitialData = () => {
  const { identify } = useAnalytics();
  const { partnerConfig } = usePartnerConfig();
  useUserInactivityInterval();
  useEffect(() => {
    identify({
      siteConfig: partnerConfig.partnerNameAnalyticsEvents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerConfig]);

  return <Outlet />;
};

const AbsoluteCenterLoader = () => <Loader isAbsoluteCenter />;

export const WithInitialData = ({ kycComplianceChecksEnabled = false }: { kycComplianceChecksEnabled?: boolean }) => {
  const { generateErrorLink } = useRouter();
  const { track } = useBaseAnalytics();
  const sendPartnerNameEvent = useRef(false);
  const { isLoading: accountIsLoading, error: accountError, data: account } = useAccount({ id: 'me' });
  const { isLoading: accountListIsLoading, isFetching: accountListIsFetching } = useAccountsWithLogoUrl();
  const { isLoading: feeIsLoading } = useFeeCatalog();
  const { isLoading: organizationPreferencesLoading } = useOrganizationPreferences();
  const { disableSalesIntegrations, identify } = useAnalytics();
  const [optimizeAccountingSoftwareDataFetching] = useFeature(
    FeatureFlags.OptimizeAccountingSoftwareDataFetching,
    false,
    { shouldTrack: false },
  );
  useUserActivityInterval();
  useUserInactivityInterval();
  useVendorsObserver();
  useFundingSourcesObserver();
  useAccountingPlatformEntities(optimizeAccountingSoftwareDataFetching);

  const { partnerGroup } = usePartnerConfig();
  const { isFetching: isSubscriptionDataFetching, isError: isSubscriptionDataError } = useSubscriptionData();

  useEffect(() => {
    const accessTokenData = getAccessTokenData();
    if (!sendPartnerNameEvent.current && accessTokenData) {
      sendPartnerNameEvent.current = true;
      track('partnerName', 'Sent', { partnerName: accessTokenData.partnerName });
    }

    if (account && accessTokenData) {
      const { organization, user, partnerName, partnerId } = accessTokenData;
      if (account.privacyPreferences?.optedOutOfSale) {
        disableSalesIntegrations();
      }
      identify({
        company: organization?.companyName,
        createOrigin: organization?.createOrigin,
        siteConfig: partnerName,
        userId: user.id,
        email: account.user.email || '',
        name: `${account.user.firstName} ${account.user.lastName}`,
        orgId: Number(organization?.id),
        registrationFlow: user.registrationFlow,
        organization: organization?.companyName,
        PartnerId: partnerId,
      });

      const datadogUser = {
        id: user.id,
        email: account.user.email || '',
        orgId: Number(organization?.id),
      };
      datadogRum.setUser(datadogUser);
      Logger.setUser(datadogUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, track]);

  const { isLoading: isLoadingMissingKycComplianceInformation } = useIsMissingKycComplianceInformation({
    enabled: kycComplianceChecksEnabled,
  });

  const ErrorComponent = () => <Navigate to={generateErrorLink(accountError)} />;
  const { isSwitching: isSwitchingOrganization } = useSwitchOrganization();

  const isLoadingAccount = accountIsLoading || accountListIsLoading;
  if ((accountError && !isLoadingAccount) || isSubscriptionDataError) {
    return <ErrorComponent />;
  }

  const isLoading =
    isLoadingAccount ||
    feeIsLoading ||
    organizationPreferencesLoading ||
    isSubscriptionDataFetching ||
    isLoadingMissingKycComplianceInformation ||
    isSwitchingOrganization;

  if (isLoading) {
    return <AbsoluteCenterLoader />;
  }

  const isFetching = accountListIsFetching;

  return (
    <WithKYCComplianceRouteGuard enabled={kycComplianceChecksEnabled}>
      <SubscriptionWrapper
        LoaderComponent={AbsoluteCenterLoader}
        ErrorComponent={ErrorComponent}
        partnerGroup={partnerGroup}
      >
        <WithLoading isLoading={isFetching} overContent isAbsoluteCenter>
          <Box hidden={isFetching} height="100%" width="100%">
            <Outlet />
          </Box>
        </WithLoading>
      </SubscriptionWrapper>
    </WithKYCComplianceRouteGuard>
  );
};

export const WithVendorActionsInitialData = () => {
  const { identify } = useAnalytics();
  useUserInactivityInterval();

  useEffect(() => {
    const accessTokenData = getAccessTokenData();
    if (accessTokenData) {
      const { organization, user, partnerName, claims } = accessTokenData;
      identify({
        company: organization?.companyName,
        createOrigin: organization?.createOrigin,
        siteConfig: partnerName,
        userId: claims?.vendorId,
        orgId: Number(organization?.id),
        registrationFlow: user.registrationFlow,
        organization: organization?.companyName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export const WithTimeoutHandling = () => {
  const { goToTimeout } = useRouter();
  const ref = React.useRef<((event: Event) => void) | null>();
  const { partnerConfig } = usePartnerConfig();
  useEffect(() => {
    if (!ref.current && partnerConfig.config.settings.supportRequestTimeoutEnable) {
      ref.current = () => {
        goToTimeout();
      };
      window.addEventListener('request_timeout_partner_redirect', ref.current);
    }
    return () => {
      if (ref.current) {
        window.removeEventListener('request_timeout_partner_redirect', ref.current);
        ref.current = null;
      }
    };
  }, [goToTimeout, partnerConfig]);

  return <Outlet />;
};
