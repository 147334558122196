import { ThemeOptions } from '@melio/penny';

import { MelioLogo, MelioLogoDark } from '../melio/logos';

export const ftiTheme: ThemeOptions = {
  logos: {
    dark: MelioLogoDark,
    light: MelioLogo,
  },
  colors: {
    global: {
      brand: {
        900: '#000F85',
        800: '#082E9E',
        700: '#285CE5',
        600: '#759FFF',
        200: '#98BDFF',
        100: '#B7D1FF',
      },
      neutral: {
        400: '#D9DEE8',
        600: '#8B95A9',
      },
    },
  },
};
