import { ThemeOptions } from '@melio/penny';

import { MelioIcons } from '@/cl/icons/melio';
import { PayPalIcon, PaypalLogo } from '@/cl/theme/partners/paypal-logo';
import Favicon from '@/images/paypal/favicon.png';

// off-the-shelf partners that we'll eventually move to store the config in db
export const payPalTheme: ThemeOptions = {
  logos: {
    dark: PaypalLogo,
    light: PaypalLogo,
  },
  colors: {
    global: {
      brand: {
        100: '#faf8f5',
        200: '#009cde',
        300: '#009cde',
        400: '#009cde',
        500: '#003087',
        600: '#003087',
        700: '#003087',
        800: '#012169',
        900: '#012169',
        1000: '#012169',
      },
    },
  },
  // @ts-ignore
  borders: {
    selection: {
      selectedBrand: '2px solid #003087',
    },
  },
};

export const PayPalIcons = {
  ...MelioIcons,
  Favicon,
  SmallLogoIcon: undefined,
  LogoIcon: PayPalIcon,
};
